import {Card} from '../../../../../app/components/card/Card';
import {Autocomplete, DateInput} from '@brightsource/brightsource-ui-lib';
import { Button } from '@blueprintjs/core';
import {autcompleteComparator, renderOptionByValue} from '../../../../../shared/utils/form.utils';
import React from 'react';
import './PipelineFilterBar.scss';
import {observer} from 'mobx-react';
import {pipelineReportStore} from '../../report.store';
import {formatFromDate, formatToDate} from '../../../../../shared/models';

export const PipelineFilterBar = observer(() => {
  const { recruiterId, fromDate, toDate, stage } = pipelineReportStore.properties;
  const { recruiterId: recruiterNameOptions= [], stage: stageOptions = [] } = pipelineReportStore.source;
  // temporary hide some recruiter
  const filteredRecruiterNameOptions = recruiterNameOptions.filter(i => !['eyal@ethosia.com', 'inbarh@ethosia.com', 'michalds@ethosia.com', 'michalm@ethosia.com', 'ofirm@ethosia.com', 'sheli@ethosia.com'].includes(i.email))

  const handleClearFilter = () => {
    pipelineReportStore.resetFilters();
  }
  
  return (<Card>
    <div className='clear-filter'>
      <Button minimal intent='primary' onClick={handleClearFilter}>
        Clear All
      </Button>
    </div>
    <div className={'pipeline-filter-bar'}>
      <Autocomplete
        label="Recruiter name"
        options={filteredRecruiterNameOptions}
        onChange={(_,  value) => pipelineReportStore.loadByFilter('recruiterId', value)}
        value={recruiterId ?? []}
        getOptionLabel={renderOptionByValue}
        isOptionEqualToValue={autcompleteComparator}
        multiple
      />

      <DateInput
        label="From Date"
        maxDate={new Date()}
        value={fromDate}
        onChange={(date) => pipelineReportStore.loadByFilter('fromDate', formatFromDate(date).toDate())}
      />

      <DateInput
        label="To Date"
        minDate={fromDate}
        value={toDate}
        onChange={date => pipelineReportStore.loadByFilter('toDate', formatToDate(date).toDate())}
      />

      <Autocomplete
        label="Stage"
        options={stageOptions}
        onChange={(_, value) => pipelineReportStore.loadByFilter('stage', value)}
        value={stage ?? null}
        getOptionLabel={renderOptionByValue}
        isOptionEqualToValue={autcompleteComparator}
      />
    </div>

  </Card>)
})
