import React, {PureComponent} from 'react';
import dayjs from 'dayjs';
import Loader from '../../../../components/loader/Loader.component';
import {TextAreaForm} from '../../../../components/forms';
import './notes.scss';
import userStore from '../../../../../stores/user.store';
import utilsService from '../../../../../services/utils.service';
import processStore from '../../../../../stores/process.store';
import DefaultImageComponent from '../../../../components/DefaultImage.component';

class Notes extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            items: [],
            noteValue: '',
            sending: false
        };
        this._disRef = React.createRef();
        this._timeInterval = null;
    }

    scrollToBottom = () => {
        this._disRef.current.scrollTop = this._disRef.current.scrollHeight;
    };

    async componentDidMount() {
        await this.getData();
        this._timeInterval = setInterval(() => this.getData(), 40000);
    }

    getData = async () => {
        const { job, process } = this.props;
        let recruiterId = null;
        const processId = process._id;
        if (job && job.recruiterInChargeId) {
            recruiterId = job.recruiterInChargeId;
        }

        const promises = [
            processStore.getDiscussions(processId)
        ];

        if (recruiterId) {
            promises.push(userStore.getUsersByIds([recruiterId]));
        }

        const [data] = await Promise.all(promises);
        const userIdsSet = new Set();
        data.forEach(d => {
            userIdsSet.add(d.userId);
        });

        await userStore.getUsersByIds(Array.from(userIdsSet));

        this.setState({
            items: data,
            loader: false
        });

        this.scrollToBottom();
    };

    inputChange = e => {
        this.setState({ noteValue: e.target.value });
    };

    buildClasses(noteValue) {
        let res = 'notes-input';

        if (noteValue) {
            res += ' have-content';
        }
        return res;
    }

    componentWillUnmount() {
        clearTimeout(this._timeInterval);
    }

    save = async () => {
        const { noteValue } = this.state;
        const { process, job, profile } = this.props;
        const recruiterInChargeProfile = userStore.users[job.recruiterInChargeSlug];

        this.setState({ sending: true });

        const dataToSend = {
            text: noteValue,
            processId: process._id,
            processSlug: process.slug,
            processEmsId: process.emsId,
            profileId: profile._id,
            profileSlug: profile.slug,
            profileEmsId: profile.emsId,
            userId: userStore.user._id,
            userSlug: userStore.user.slug,
            userEmsId: userStore.user.emsId,
            syncEms: false,
            recruiterId: recruiterInChargeProfile ? recruiterInChargeProfile._id : null,
            recruiterEmsId: recruiterInChargeProfile ? recruiterInChargeProfile.emsId : null,
            recruiterSlug: recruiterInChargeProfile ? recruiterInChargeProfile.slug : null,
            jobId: job._id,
            jobSlug: job.slug,
            jobEmsId: job.emsId,
            sender: 'recruiter'
        };

        const resp = await processStore.postDiscussion(dataToSend);
        this.setState({ sending: false, items: resp, noteValue: '' });
        this.scrollToBottom();
    };

    cancel = () => {
        this.setState({
            noteValue: ''
        });
    };

    buildButtonClasses(sending, saveButtonDisabled) {
        let classes = 'button black';
        if (saveButtonDisabled) {
            classes += ' disabled';
        }
        if (sending) {
            classes += ' loading';
        }

        return classes;
    }

    normalazeItems(items) {
        let res = [];
        if (items && items.length > 0) {
            res = items.map(item => {
                const text = item.text.replace('\\n', '↵');
                item.text = text.replace('n\\', '↵');
                return item;
            });
        }

        return res;
    }

    render() {
        const { loader, items, noteValue, sending } = this.state;
        const classes = this.buildClasses(noteValue);
        const saveButtonDisabled = !noteValue;
        const buttonClasses = this.buildButtonClasses(sending, saveButtonDisabled);
        const normalItems = this.normalazeItems(items);

        return (
            <div className='notes-wrapper'>
                {loader && (<Loader/>)}

                {!loader && normalItems && (
                    <div className="notes-items">
                        <div
                            ref={this._disRef}
                            className="notes-scroll custom-scroll">
                            {normalItems.map(item => {
                                return (
                                    <NoteItem
                                        key={item._id}
                                        data={item}
                                    />
                                );
                            })}
                        </div>
                        <div className={classes}>
                            <TextAreaForm
                                value={noteValue}
                                placeHolder={'Type to send a message'}
                                type={'text'}
                                inputChange={this.inputChange}
                            />
                            <div className="buttons-group">
                                <button
                                    onClick={this.cancel}
                                    className="button gray">Cancel
                                </button>
                                <button
                                    onClick={this.save}
                                    className={buttonClasses}
                                    disabled={saveButtonDisabled}>Send
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default Notes;

const NoteItem = props => {
    const { data } = props;
    const user = userStore.users[data.userSlug];
    const displayName = utilsService.getDisplayName(user);

    const samePerson = () => {
        return user._id === userStore.user._id;
    };

    return (
        <div className='note-item'>
            <div className="img">
                <DefaultImageComponent
                    url={utilsService.getProfileImage(user)}
                    alt={displayName}
                    profileName={displayName}
                    isExposed={true}
                />
            </div>
            <div className="note-wrapper">
                <div className="note-info">
                    <span>{samePerson() ? 'Me' : displayName} ∙ </span>
                    {dayjs(data.dateCreated).format('HH:mm, MMM D, YYYY')}
                </div>
                <div className="note-text" dir='auto'>
                    {data.text}
                </div>
            </div>

        </div>
    );
};
