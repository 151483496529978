import {action, observable} from 'mobx';
import uniqueBy from 'lodash/uniqBy';
import fetchService from '../../../services/fetch.service';
import utilsService from '../../../services/utils.service';
import jobStore from "../../../stores/job.store";
import userStore from "../../../stores/user.store";
import companyStore from "../../../stores/company.store";
import processStore from "../../../stores/process.store";
import profileStore from "../../../stores/profile.store";
import dayjs from "dayjs";
import notificationsStore from "../../../stores/notifications.store";
import {PAYMENT_TYPES} from "../../../app/scenes/billing/billing.constant";
import {billApiClient} from "../api/bill-api.client";
import {mapUsersToHandlers} from "./billing.mapper";
import {JOB_EVENTS} from "../../jobs/job.constant";


class BillingStore {
    bonus = {};
    billing = {};

    @observable
    isSubmitting = false;
    @observable
    isLoadingDetail = false;

    @observable
    newOrder = {
        companyOptions: [],
        jobOptions: [],
        contactPersonOptions: [],
        handlerOptions: [],
        candidateOptions: [],
    };
    @observable
    modeFlags = {
        isEditOrder: false,
        isEditInvoice: false,
        isEditReceipt: false,
        isCancel: false
    }
    @observable
    billingDetail = {}

    @observable
    overviewFilters = {
        isApplySearch: true,
        page: 0,
        limit: 20,
        company: null,
        candidate: null,
        job: null,
        projectType: null,
        paymentMonth: null,
        orderMonth: null,
        paymentStatus: null
    }

    @observable
    overviewBills = {
        items: [],
        total: 0,
        isLoading: false
    }

    @action.bound
    async getBonusData(process) {
        const processId = process.processId;
        const body = {'field': 'processId', 'value': processId};
        const response = await fetchService.authenticatedPost(`/bonus-data/${processId}`, body);
        this.bonus = utilsService.normalizeApiData(response.data, 'processSlug');
    }

    @action.bound
    async updataBilling(filter) {
        const body = filter;
        const response = await fetchService.authenticatedPost(`/billing-update`, body);
        // const normalData = utilsService.normalizeApiData(response.data);
        // this.billing = normalData;
        this.billing = response.data;
        return this.billing;
    }

    @action.bound
    async getBillingData(filter) {
        const body = filter;
        const response = await fetchService.authenticatedPost(`/billing-data`, body);
        this.billingList = response.data;
        return this.billingList;
        // this.billingList = utilsService.normalizeApiData(response.data, 'slug');
    }

    @action.bound
    async getBillingDetail(slug) {
        const response = await fetchService.authenticatedPost(`/billing-data`, {slug});
        const data = response.data;

        this.billing = data[0];

        return this.billing
    }

    @action.bound
    async getBonusPaymentsData(filter) {
        const body = filter;
        const response = await fetchService.authenticatedPost(`/bonus-payments-data`, body);
        this.bonusPaymentsList = response.data;
        return this.bonusPaymentsList;
        // this.bonusPaymentsList = utilsService.normalizeApiData(response.data, 'slug');
    }

    @action.bound
    async updataBonusPayment(filter) {
        const body = filter;
        const response = await fetchService.authenticatedPost(`/bonus-payment-update`, body);
        // const normalData = utilsService.normalizeApiData(response.data);
        // this.billing = normalData;
        this.bonusPayment = response.data;
        return this.bonusPayment;
    }

    @action.bound
    async findBills() {
        function formatMonthFilter(value) {
            return value
              ? dayjs(value).format('MM/YYYY')
              : value
        }
        this.overviewBills.isLoading = true;

        const filters = {
            search: '',
            page: this.overviewFilters.page,
            limit: this.overviewFilters.limit,
            companyId: this.overviewFilters.company?.elId,
            jobId: this.overviewFilters.job?._id,
            profileSlug: this.overviewFilters.candidate?.profileSlug,
            orderMonth: formatMonthFilter(this.overviewFilters.orderMonth),
            paymentMonth: formatMonthFilter(this.overviewFilters.paymentMonth),
            paymentStatus: this.overviewFilters.paymentStatus?.elId,
            projectTypeId: this.overviewFilters.projectType?.elId
        }

        const response = await billApiClient.findMany(filters);

        if (!response.data) {
            return response;
        }

        const  { items, total } = response.data;

        this.overviewBills = {
            items,
            total,
            isLoading: false
        }

        return response;
    }

    @action.bound
    updateOverviewFilters(key, value) {
        this.overviewFilters['page'] = 0;
        this.overviewFilters[key] = value;
        this.overviewFilters['isApplySearch'] = true;

        this.findBills();
    }

    @action.bound
    updatePage(value) {
        this.overviewFilters['page'] = value;
        this.overviewFilters['isApplySearch'] = true;
        this.findBills();
    }

    @action.bound
    async createOrder(body) {
        this.isSubmitting = true;
        const payload = this.billingDetail._id ? { ...body, _id: this.billingDetail._id } : body;
        const response = await billApiClient.saveOrder(payload);
        this.isSubmitting = false;
        return response;
    }

    async createPayment(billingId, body) {
        this.isSubmitting = true;
        const response = await billApiClient.savePayment(billingId, body);
        this.isSubmitting = false;
        return response;
    }

    async removeBill(billingId) {
        this.isSubmitting = true;
        const response = await fetchService.authenticatedDelete(`/v2/billings/${billingId}`);
        this.isSubmitting = false;
        return response;
    }

    async cancelBill(billingId, reasonId = 0){
        this.isSubmitting = true;
        const response = await billApiClient.cancelBill(billingId, reasonId);
        this.isSubmitting = false;
        return response;
    }

    @action.bound
    commitNewOrderOptions(key, value) {
        this.newOrder[key] = value;
    }

    @action.bound
    async dispatchLoadJobs(companyId, { isAll = false } = {}) {
        const OPEN_STATUS = 1;
        const CLOSED_STATUS = 2;
        const statuses = isAll ? [OPEN_STATUS, CLOSED_STATUS] : [OPEN_STATUS];
        const companyRecruiterJobs = await jobStore.getRecruiterJobsByCompany(companyId, false, false, statuses);

        const jobOptions = companyRecruiterJobs.map(item => {
            return {
                elId: item._id,
                value: item.title,
                status: item.status,
                _id: item._id,
                name: item.title,
                location: item.locationData,
                projectType: item.projectType,
                ...item
            };
        });

        this.commitNewOrderOptions('jobOptions', jobOptions);
        return jobOptions;
    }

    @action.bound
    async dispatchLoadHandlers() {
        function compare(a, b) {
            if (a.value < b.value) {
                return -1;
            }
            if (a.value > b.value) {
                return 1;
            }
            // a must be equal to b
            return 0;
        }

        await userStore.getRecruiters();

        const handlers = mapUsersToHandlers(userStore.recruitersList)
          .filter(handler => handler.value.length > 1)
          .sort(compare);

        this.commitNewOrderOptions('handlerOptions', handlers);
    }

    @action.bound
    async dispatchLoadCompanies(text, hasJob = true) {
        const searchOptions = hasJob ? {limit: 10, hasJobs: true} : {limit: 10};

        const companies = await companyStore.searchCompaniesByPartialNameByText(text, searchOptions)

        this.commitNewOrderOptions('companyOptions', companies);
    }

    @action.bound
    async getContactPerson(job) {
        const {slug} = job;

        const jobDetail = await jobStore.getJobBySlug(slug);

        const mainJobContactPersonId = jobDetail?.hrManagers?.[0];

        if (!mainJobContactPersonId) return;

        const mainContactPerson = await userStore.getUserById(mainJobContactPersonId);

        return {
            elId: mainContactPerson._id,
            value: utilsService.getDisplayName(mainContactPerson),
            name: utilsService.getDisplayName(mainContactPerson),
            profileImage: utilsService.getProfileImage(mainContactPerson),
            companyName: mainContactPerson?.profile?.company,
            ...mainContactPerson
        };
    }

    @action.bound
    async dispatchLoadCandidates({job: item, projectType}) {
        const {_id, slug} = item;

        const [jobProcesses] = await Promise.all([
            processStore.getProcessesByJobsIds([_id], true),
            jobStore.getJobBySlug(slug),
        ]);

        const candidateProfileIds = jobProcesses.map(process => {
            return process.profile;
        });

        await profileStore.getProfilesByIds(candidateProfileIds);

        if (!jobProcesses?.length) {
            return;
        }

        const candidateOptions = jobProcesses
          .map(process => {
            const profile = profileStore.profiles[process.profileSlug];

            if (!profile) {
                return null;
            }

            const hiredEventLog = process?.eventLog?.find(log => JOB_EVENTS.HIRED === log?.eventTypeId);
            const decisionMakerUserId = process?.eventLog.find(log => log?.decisionMakerUserId)?.decisionMakerUserId;
            const senderMakerUserId = utilsService.getProcessSender(process, true);
            const signingDate = hiredEventLog?.lastUpdate;

            return {
                elId: profile.slug,
                value: utilsService.getDisplayName(profile),
                candidateName: utilsService.getDisplayName(profile),
                profileSlug: process.profileSlug,
                processId: process._id,
                processSlug: process.slug,
                processBsId: process.bsId,
                status: process.status,
                jobId: process.job,
                jobSlug: process.jobSlug,
                grossSalary: process.salaryOffer,
                lastUpdate: signingDate,
                decisionMakerUserId,
                senderMakerUserId
            }
          })
          .filter(Boolean);

        const uniqueCandidateOptions = uniqueBy(candidateOptions, item => `${item.profileSlug}${item.jobSlug}`);

        this.commitNewOrderOptions('candidateOptions', uniqueCandidateOptions)
    }

    @action.bound
    resetField(field) {
        switch (field) {
            case 'company':
                this.newOrder.jobOptions = [];
                this.newOrder.candidateOptions = [];
                break;
            case 'job':
                this.newOrder.candidateOptions = [];
                break;
            default:
                throw new Error('Invalid field reset ', field);
        }
    }

    @action.bound
    async loadBillingToForm(slug) {
        this.isLoadingDetail = true;
        await billingStore.getBillingDetail(slug);

        if (!billingStore.billing) {
            this.isLoadingDetail = false;
            notificationsStore.showToast('Not found billing', 'failure');
            return;
        }

        const billingForm = {...this.billing};

        function formatDate(dateString) {
            if (!dateString) return null;

            return dayjs(dateString).toDate();
        }

        billingForm.signingDate = formatDate(this.billing.signingDate);
        billingForm.candidate = {...this.billing.process};
        billingForm.receiptDate = formatDate(this.billing.receiptDate);
        billingForm.invoiceDate = formatDate(this.billing.invoiceDate);
        billingForm.startDate = formatDate(this.billing.startDate);
        billingForm.companyInternalNote = this.billing.invoiceNote;
        billingForm.receiptNumber = this.billing.receptionNumber;
        billingForm.signingDate = formatDate(this.billing.signingDate);
        billingForm.author = this.billing.createdBy;
        billingForm.candidateSource = this.billing.source;
        billingForm.commission = this.billing.commissionPercent;
        billingForm.contactPerson = this.billing.paymentOrderTo;
        billingForm.paymentTerm = this.billing.paymentTerm;
        billingForm.sapCode = this.billing.sapCode;
        billingForm.billingOrderNumber = this.billing.billingOrderNumber;

        this.billingDetail = {...billingForm};

        const { paymentType } = this.billing;

        this.modeFlags = {
            isEditOrder: true,
            isEditInvoice: [PAYMENT_TYPES.INVOICE, PAYMENT_TYPES.RECEIPT].includes(paymentType),
            isEditReceipt: PAYMENT_TYPES.RECEIPT === paymentType,
            isCancel: PAYMENT_TYPES.CANCEL === paymentType,
        }
        this.isLoadingDetail = false;

        const { company, job, projectType, contactPerson } = billingStore.billing;

        // Load options
        await Promise.all([
            company ? companyStore.getCompanyBySlug(company.slug) : null,
            job ? jobStore.getJobBySlug(job.slug) : null,
            contactPerson ? userStore.getUserById(contactPerson.elId): null,
            this.dispatchLoadHandlers(),
            company ? this.dispatchLoadCompanies(company.name) : null,
            company ? this.dispatchLoadJobs(company.elId) : null,
            (job && company) ? this.dispatchLoadCandidates({ job, selectedCompany: company, projectType }): null,
            company ? companyStore.getCompanyManagement(company.elId) : null
            // company ? billingStore.dispatchLoadCompanyHandlers(company.elId) : null,
            // job ? billingStore.dispatchLoadJobHandlers(job.slug) : null,
        ]);
    }

    @action.bound
    unmount() {
        this.newOrder = {
            companyOptions: [],
            jobOptions: [],
            contactPersonOptions: [],
            handlerOptions: [],
            candidateOptions: []
        }
    }
}

const billingStore = new BillingStore();

export default billingStore;
export { billingStore }
