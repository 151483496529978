import {action, observable} from 'mobx';
import fetchService from '../services/fetch.service';
import notificationsStore from './notifications.store';

class MetaDataStore {
    @observable categories = {};
    @observable categoryOptions = {};
    @observable isLoading = false;

    @action.bound
    async getCategory(category, asIs) {
        if (!this.categories[category]) {
            const response = await fetchService.authenticatedGet(
                `/meta-data/${category}`);
            if (asIs) {
                this.categories[category] = response?.data;
            } else {
                this.categories[category] = Object.values(response?.data);
            }
        }
    }

    async getCategories(categories, forceGetData = false) {
        this.isLoading = true;
        const requestCategories = categories.filter(category => !this.categoryOptions[category]);

        if (requestCategories.length || forceGetData) {
            const { data } = await fetchService.authenticatedGet(`/meta-data?categories=${requestCategories.length ? requestCategories.join(',') : categories.join(',')}`);

            Object.keys(data).forEach(key => {
                if (key === 'skills') {
                    this.categoryOptions[key] = Object.values(data[key] ?? {}).sort((a, b) => a.value.localeCompare(b.value));
                } else {
                    this.categoryOptions[key] = Object.values(data[key] ?? {});
                }
            });
        }

        this.isLoading = false;
    }

    @action.bound
    async getSynonymsByCategoryAndValue(category, value) {
        const response = await fetchService.authenticatedGet(
            `/synonyms/${category}/items?value=${value}`);
         return response && response.data
    }

    @action.bound
    async getSynonymsByCategoryAndIds(category, ids) {
        const response = await fetchService.authenticatedGet(
            `/synonyms/${category}/items?ids=${ids}`);
         return response && response.data
    }

    //locations = [];
    @action.bound
    async getLocations() {
        const response = await fetchService.authenticatedGet(`/locations`);
        this.locations = response && response.data;
        return this.locations;
    }

    @action.bound
    async getWorldCities(query) {
        const response = await fetchService.authenticatedGet(`/worldcities?${query}`);
        this.locations = response && response.data;
        return this.locations;
    }

    @action.bound
    async findSynonymsByCategory(category, query) {
        const response = await fetchService.authenticatedGet(`/synonyms/search-by-category/${category}?${query}`);
        return response && response.data;
    }

    @action.bound
    async getSkills(query) {
        const response = await fetchService.authenticatedGet(`/meta-data-skills/admin?${query}`);
        return response && response.data;
    }

    @action.bound
    async updateSkills(payload) {
        const response = await fetchService.authenticatedPatch(`/meta-data-skills/admin`, payload);
        if ( response.code == 200) {
            notificationsStore.showToast('Skill is updated successfully', 'success');
        } else {
            notificationsStore.showToast('Failed to update skill. Please try again.', 'failure');
            return;
        }

        return response;
    }

    @action.bound
    async getTitlesCombined(query) {
        const response = await fetchService.authenticatedGet(`/meta-data-roles/combined-title?${query}`);
        return response && response.data;
    }

    @action.bound
    async updateCombinedTitle(payload) {
        const response = await fetchService.authenticatedPatch(`/meta-data-roles/combined-title`, payload);
        if ( response.code == 200) {
            notificationsStore.showToast('Title is updated successfully', 'success');
        } else {
            notificationsStore.showToast('Failed to update title. Please try again.', 'failure');
            return;
        }

        return response;
    }

    @action.bound
    async updateSuperTitle(payload) {
        const response = await fetchService.authenticatedPatch(`/meta-data-roles/super-title`, payload);
        if ((response.code == 200 || response.code == 204) && !response.data.error) {
            notificationsStore.showToast('Super title is updated successfully', 'success');
            metaDataStore.getCategories(['roles'], true);
        } else {
            notificationsStore.showToast(`${response.data.error ? response.data.errorMessage : 'Failed to update super title'}. Please try again.`, 'failure');
            return;
        }
        return response;
    }

    @action.bound
    async createCombinedTitle(payload) {
        const response = await fetchService.authenticatedPost(`/meta-data-roles/combined-title`, payload);
        if ( response.code == 200) {
            notificationsStore.showToast('Title is created successfully', 'success');
        } else {
            notificationsStore.showToast('Failed to create title. Please try again.', 'failure');
            return;
        }

        return response;
    }

    @action.bound
    async createSuperTitle(payload) {
        const response = await fetchService.authenticatedPost(`/meta-data-roles/super-title`, payload);
        if ((response.code == 200 || response.code == 204) && !response.data.error) {
            notificationsStore.showToast('Super title is created successfully', 'success');
            metaDataStore.getCategories(['roles'], true);
        } else {
            notificationsStore.showToast(`${response.data.error ? response.data.errorMessage : 'Failed to create super title'}. Please try again.`, 'failure');
            return;
        }
        return response;
    }

    @action.bound
    async createJobFunction(payload) {
        const response = await fetchService.authenticatedPost(`/meta-data-roles/job-function`, payload);

        if (response.data?.error) {
            const errorMessage = response.data?.errorMessage || 'Failed to create job function';
            notificationsStore.showToast(errorMessage, 'failure');
        } else {
            notificationsStore.showToast('Job function is created successfully', 'success');
            metaDataStore.getCategories(['categories'], true);
        }
    }

    @action.bound
    async getCountries() {
        if (this.categories['countries']) return;

        const response = await fetchService.authenticatedGet('/countries');
        const data = response?.data || [];

        const countries = data.map((country) => ({
            ...country,
            elId: country.countryId,
            value: country.countryName,
        }));

        this.categories['countries'] = countries;
    }

    @action.bound
    async removeSystemTableItem(category, elId) {
        const response = await fetchService.authenticatedDelete(`/system-table/${category}/${elId}`);
        if ((response.code == 200 || response.code == 204) && !response.data.error) {
            notificationsStore.showToast(`This ${category} is deleted successfully`, 'success');
            metaDataStore.getCategories([category], true);
        } else {
            notificationsStore.showToast(`${response.data.error ? response.data.errorMessage : `Failed to delete ${category}`}. Please try again.`, 'failure');
            return;
        }
        return response;
    }

    @action.bound
    async createSystemTableItem(category, body) {
        const response = await fetchService.authenticatedPost(`/system-table/${category}`, body);
        if ((response.code == 200 || response.code == 204) && !response.data.error) {
            notificationsStore.showToast(`This ${category} is create successfully`, 'success');
            metaDataStore.getCategories([category], true);
        } else {
            notificationsStore.showToast(`${response.data.error ? response.data.errorMessage : `Failed to create ${category}`}. Please try again.`, 'failure');
            return;
        }
        return response;
    }

    @action.bound
    async updateSystemTableItem(category, body) {
        const response = await fetchService.authenticatedPatch(`/system-table/${category}/${body.elId}`, body);
        if ((response.code == 200 || response.code == 204) && !response.data.error) {
            notificationsStore.showToast(`This ${category} is updated successfully`, 'success');
            metaDataStore.getCategories([category], true);
        } else {
            notificationsStore.showToast(`${response.data.error ? response.data.errorMessage : `Failed to update ${category}`}. Please try again.`, 'failure');
            return;
        }
        return response;
    }
}

const metaDataStore = new MetaDataStore();

export default metaDataStore;
