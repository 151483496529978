import { action, observable } from 'mobx';
import fetchService from '../services/fetch.service';
import utilsService from '../services/utils.service';
import data from '../constants/data';
import jobStore from './job.store';
import profileStore from './profile.store';
import companyStore from './company.store';
import notificationsStore from './notifications.store';
import constants from '../constants/constants';
import userStore from './user.store';

class ProcessStore {
    @observable processes = {};
    @observable allProcesses = {};
    @observable processesByProfileId = [];
    @observable processesByProfileIdData = [];
    @observable processesLoaded = false;
    @observable processesPipelinesGrid = [];
    @observable processesPipelinesSingleJobGrid = [];
    @observable processesPipelinesSingleCompanyGrid = [];
    @observable processesPipelinesSingleJobTableByJobId = {};
    @observable processesPipelinesGridAll = [];
    @observable processesPipelinesStatusCounter = {};
    @observable processesSingleJobStatusCounter = {};
    @observable processesCompanyStatusCounter = {};
    @observable processToShow = null;
    @observable searchResults = [];
    pipeLinesSet = false;
    tableLastIndex = null;
    tableLastIndexSingleJob = null;
    tableLastIndexCompanyJob = null;
    latestJobSlug = null;
    processesIdsForProfileNavigation = {
        jobSlug: null,
        ids: [],
        index: 0
    };
    @observable candidateForDefaultValue = data.pipeline_tabs_text.CandidateFor;
    @observable isEmptySingleJobGrid = null;

    getIdsByJobProfileCompany = (keys, isAll) => {
        const companySet = new Set();
        const jobSet = new Set();
        const profileSet = new Set();

        const processes = isAll ? this.allProcesses : this.processes;

        for (let i = 0; i < keys.length; i++) {
            if (processes[keys[i]].companySlug) {
                companySet.add(processes[keys[i]].companySlug);
            }
            if (processes[keys[i]].jobSlug) {
                jobSet.add(processes[keys[i]].jobSlug);
            }
            if (processes[keys[i]].profileSlug) {
                profileSet.add(processes[keys[i]].profileSlug);
            }
        }
        return {
            company: Array.from(companySet),
            job: Array.from(jobSet),
            profile: Array.from(profileSet),
        };
    };

    @action.bound
    async getProcesses(statuses, jobsSlugs) {
        //if (this.processesLoaded) return;

        let response = [];
        if (statuses) {
            const chunks = utilsService.splitArrayIntoChunks(jobsSlugs, 30);
            const promises = chunks.map(chunk => {
                return fetchService.authenticatedGet(`/processes?statuses=${utilsService.getProcessesStatuses().join(',')}&jobs=${chunk.join(',')}`);
            });

            const resp = await Promise.all(promises);
            let data = [];
            resp?.forEach(resp => {
                data = Array.isArray(resp.data) ? [...data, ...resp.data] : data;
            });
            response = data;
        } else {
            const resp = await fetchService.authenticatedGet('/processes');
            response = resp.data;
        }

        this.processes = utilsService.normalizeApiData(response);
        this.allProcesses = utilsService.mergeApiData(this.processes, this.allProcesses);

        this.setTableCounters();
        this.processesLoaded = true;

    }

    @action.bound
    async getProcessesByProfileIdsAndJobsIds(profileIds, jobsIds, statuses, filterType) {

        let request = `/processes-profiles-jobs?profileIds=${profileIds.join(',')}&jobIds=${jobsIds.join(',')}`;
        if (statuses && statuses.length > 0) {
            request = `${request}&statuses=${statuses.join(',')}`;
        }
        if (filterType) {
            request = `${request}&filterType=${filterType}`;
        }
        let response = await fetchService.authenticatedGet(request);
        if (!filterType) {
            this.processes = utilsService.normalizeApiData(response.data);
            this.allProcesses = utilsService.mergeApiData(this.processes, this.allProcesses);

            this.setTableCounters();
        }

        return response.data
    }

    @action.bound
    async getProcessesByCompanyId(companyId) {
        const response = await fetchService.authenticatedGet(
            `/processes-company?status=${utilsService.getProcessesStatuses().join(',')}&companyId=${companyId}`
          );
        const processes = response && Array.isArray(response.data) ? response.data : [];

        const normalizedData = utilsService.normalizeApiData(processes);
        
        this.processes = normalizedData;
        this.allProcesses = utilsService.mergeApiData(normalizedData,
            this.allProcesses);

        this.setTableCounters();
        return processes;
    }

    @action.bound
    async getProcessesByProfileId(profileId, statuses) {
        let response = [];
        let normalizedProcesses;
        if (statuses) {
            response = await fetchService.authenticatedGet(`/processes-profile?profileId=${profileId}&statuses=${statuses.join(',')}`);
            
            normalizedProcesses = utilsService.normalizeApiData(response.data);
            this.processes = normalizedProcesses;
        } else {
            response = await fetchService.authenticatedGet(`/processes-profile?profileId=${profileId}`);
            this.processesByProfileId = response.data;
            normalizedProcesses = utilsService.normalizeApiData(response.data);
        }

        this.allProcesses = utilsService.mergeApiData(normalizedProcesses,
            this.allProcesses);
        return this.processesByProfileId;
    }

    @action.bound
    async getProcessesByProfileIds(profileIds) {
        if (profileIds.length > 0) {
            const response = await fetchService.authenticatedGet(`/processes-profiles?profileIds=${profileIds}`);
            const normalizedProcesses = utilsService.normalizeApiData(response.data);
            this.processes = normalizedProcesses;
            this.allProcesses = utilsService.mergeApiData(normalizedProcesses,
                this.allProcesses);
            return response.data;
        }
        else {
            this.processes = [];
            return [];
        }
    }

    @action.bound
    async getProcessesByJobsIds(jobsIds, forBilling = false) {
        const uniqIds = utilsService.uniqArrayValues(jobsIds);
        if (uniqIds.length > 0) {
            const chunks = utilsService.splitArrayIntoChunks(uniqIds, 30);
            const promises = chunks.map(chunk => {
                return fetchService.authenticatedGet(`/processes-jobs?jobsIds=${chunk.join(',')}&forBilling=${forBilling}`);
            });

            const response = await Promise.all(promises);

            let data = [];
            response?.forEach(resp => {
                data = Array.isArray(resp.data) ? [...data, ...resp.data] : data;
            });

            const normalizedData = utilsService.normalizeApiData(data);
            this.allProcesses = utilsService.mergeApiData(normalizedData,
                this.allProcesses);
            return data;
        } else {
            this.processes = [];
            return [];
        }
    }

    @action.bound
    async getProcessesByRecruiter(body) {
        const response = await fetchService.authenticatedPost(`/processes-recruiter`, body);
        if (response) {
            return response.data;
        }
        return [];
    }

    @action.bound
    async getProcessById(id) {
        const slug = this.findProcessSlugById(id);
        if (this.processes[slug]) {
            this.processToShow = this.processes[slug];
        } else {
            const response = await fetchService.authenticatedGet(`/processes/${id}`);
            const normalizedData = utilsService.normalizeApiData([response.data]);
            this.processes = utilsService.mergeApiData(normalizedData, this.processes);
            this.allProcesses = utilsService.mergeApiData(normalizedData,
                this.allProcesses);
        }
    }

    @action.bound
    async getProcessesByIds(ids) {
        const filteredIds = utilsService.filterStoreIds(ids,
            Object.keys(this.processes));
        if (filteredIds.length > 0) {
            const response = await fetchService.authenticatedGet(
                `/processes?ids=${filteredIds.join(',')}`);
            const normalizedData = utilsService.normalizeApiData(response.data);
            this.processes = utilsService.mergeApiData(normalizedData,
                this.processes);
            this.allProcesses = utilsService.mergeApiData(normalizedData,
                this.allProcesses);
        }
    }

    @action.bound
    async getProcessesBySlugs(slugs) {
        const filteredIds = utilsService.filterStoreIds(slugs,
            Object.keys(this.processes));
        if (filteredIds.length > 0) {
            const response = await fetchService.authenticatedGet(
                `/processes?slugs=${filteredIds.join(',')}`);
            const normalizedData = utilsService.normalizeApiData(response.data);
            this.processes = utilsService.mergeApiData(normalizedData,
                this.processes);
            this.allProcesses = utilsService.mergeApiData(normalizedData,
                this.allProcesses);
        }
    }

    @action.bound
    async createProcesses(body) {
        const response = await fetchService.authenticatedPost(`/processes`, body);
        const normalizedData = utilsService.normalizeApiData(response.data);
        this.allProcesses = utilsService.mergeApiData(normalizedData,
            this.allProcesses);
        return response.data;
    }

    @action.bound
    filterProcesses(columnIndex) {
        this.tableLastIndex = columnIndex;
        const options = data.applicant_table_options;
        if (columnIndex === 0) {
            this.processesPipelinesGrid = this.processesPipelinesGridAll;
        } else {
            this.processesPipelinesGrid = this.processesPipelinesGridAll.filter(
                p => p.statusString === options[columnIndex]);
        }
        processStore.candidateForDefaultValue = data.pipeline_tabs_text.CandidateFor;
    }

    @action.bound
    filterProcessesByStatusAndJob(columnIndex, jobSlug) {
        this.tableLastIndexSingleJob = columnIndex;
        const options = data.applicant_table_options_single_job;

        this.processesPipelinesSingleJobGrid = this.processesPipelinesGridAll.filter(
          (p) => (columnIndex === 0 || p.statusString === options[columnIndex]) && p.jobSlug === jobSlug
        );

        processStore.candidateForDefaultValue = data.pipeline_tabs_text.CandidateFor;
    }

    @action.bound
    filterProcessesByStatusAndCompany(columnIndex, companySlug) {
        this.tableLastIndexCompanyJob = columnIndex;
        const options = data.applicant_table_options;

        // this code sucks
        this.processesPipelinesSingleCompanyGrid = this.processesPipelinesGridAll.filter(p =>
            (columnIndex === 0 || p.statusString === options[columnIndex])
            && p.companySlug === companySlug && (p.jobData.status === 1 || p.jobData.status === 2)
            && (!this.latestJobSlug || p.jobSlug === this.latestJobSlug));

        // processStore.candidateForDefaultValue = data.pipeline_tabs_text.CandidateFor;
    }

    @action.bound
    filterProcessesByStatusesNumber(arrayOfStatusesNums) {
        this.processesPipelinesGrid = this.processesPipelinesGridAll.filter(
            p => arrayOfStatusesNums.includes(p.status));
    }

    async getJobProcessesByStatusesNumber(jobSlug, arrayOfStatusesNums) {
        const keys = Object.keys(this.processes);
        const length = keys.length;
        const filteredProcesses = [];
        for (let i = length - 1; i >= 0; i--) {
            const key = keys[i];
            const process = this.processes[key];
            if (process.jobSlug === jobSlug && arrayOfStatusesNums.includes(process.status)) {
                await Promise.all([
                    profileStore.getProfilesBySlugs([process.profileSlug]),
                    companyStore.getCompanyBySlug(process.companySlug)
                ]);

                process.profileData = await profileStore.profiles[process.profileSlug];
                process.companyData = companyStore.companies[process.companySlug];
                process.profileImageUrl = utilsService.getProfileImage(process.profileData);
                process.profileName = process.profileData ? process.profileData.firstName + ' ' + process.profileData.lastName : '';

                filteredProcesses.push(process);
            }
        }

        return filteredProcesses;
    }

    @action.bound
    filterProcessesByJobSlug(jobSlug, companySlug) {
        this.latestJobSlug = jobSlug;
        const options = data.applicant_table_options;

        if (jobSlug) {
            this.processesPipelinesGrid = this.processesPipelinesGridAll.filter(
                p => p.jobSlug === jobSlug && (!this.tableLastIndex || p.statusString === options[this.tableLastIndex]));

            this.processesPipelinesSingleCompanyGrid = this.processesPipelinesGridAll.filter(
                p => p.jobSlug === jobSlug && (!this.tableLastIndexCompanyJob || p.statusString === options[this.tableLastIndexCompanyJob]));
        }
        else {
            if (!this.tableLastIndex) { // All
                this.processesPipelinesGrid = this.processesPipelinesGridAll;
            } else {
                this.processesPipelinesGrid = this.processesPipelinesGridAll.filter(
                    p => p.statusString === options[this.tableLastIndex]);
            }

            this.filterProcessesByStatusAndCompany(this.tableLastIndexCompanyJob, companySlug);
        }

        this.setSingleCompanyTableCounters(companySlug);
    }

    @action.bound
    getTableIndex() {
        if (this.pipeLinesSet) return null;
        this.pipeLinesSet = true;
        let tab = null;
        let colIndex = 0;
        const options = data.applicant_table_options;
        options.forEach((item, index) => {
            if (!tab && this.processesPipelinesStatusCounter[item]) {
                tab = item;
                colIndex = index;
            }
        });

        if (this.tableLastIndex !== null) {
            colIndex = this.tableLastIndex;
        }

        this.filterProcesses(colIndex);
        return colIndex;
    }

    @action.bound
    setTableCounters() {
        const tempObj = {
            All: 0,
            New: 0,
            'In-progress': 0,
            Interview: 0,
            Offered: 0,
            Hired: 0,
            Leads: 0,
            Withdrawn: 0,
            Decline: 0
        };

        const keys = Object.keys(this.processes);
        const length = keys.length;

        // count for each status
        for (let i = length - 1; i >= 0; i--) {
            const key = keys[i];
            const p = this.processes[key];
            tempObj.All++;
            tempObj[utilsService.getStatusByEnum(p.status)]++;
        }

        this.processesPipelinesStatusCounter = tempObj;
    }

    @action.bound
    setSingleJobTableCounters(jobSlug) {
        const tempObj = {
            All: 0,
            New: 0,
            'In-progress': 0,
            Interview: 0,
            Offered: 0,
            Hired: 0,
            Leads: 0,
            Decline: 0,
            Withdrawn: 0
        };

        const keys = Object.keys(this.processesPipelinesGridAll);
        const length = keys.length;
        let isEmpty = true;

        // count for each status
        for (let i = length - 1; i >= 0; i--) {
            const key = keys[i];
            const p = this.processesPipelinesGridAll[key];
            if (p.jobSlug === jobSlug) {
                isEmpty = false;
                tempObj.All++;
                tempObj[utilsService.getStatusByEnum(p.status)]++;
            }
        }

        this.isEmptySingleJobGrid = isEmpty;
        this.processesSingleJobStatusCounter = tempObj;
    }

    @action.bound
    setSingleCompanyTableCounters(companySlug) {
        const tempObj = {
            All: 0,
            New: 0,
            'In-progress': 0,
            Interview: 0,
            Offered: 0,
            Hired: 0,
            Leads: 0,
            Decline: 0,
            Withdrawn: 0
        };

        const processes = this.processesPipelinesGridAll.filter(p => !this.latestJobSlug || p.jobSlug === this.latestJobSlug);
        const keys = Object.keys(processes);
        const length = keys.length;

        // count for each status
        for (let i = length - 1; i >= 0; i--) {
            const key = keys[i];
            const p = processes[key];
            const job = jobStore.allJobs[p.jobSlug];
            if (job && (job.status === 1 || job.status === 2)) {
                if (p.companySlug === companySlug) {
                    tempObj.All++;
                    tempObj[utilsService.getStatusByEnum(p.status)]++;
                }
            }
        }

        this.processesCompanyStatusCounter = tempObj;
    }

    resetFilterTable() {
        this.processesPipelinesGrid = this.processesPipelinesGridAll;
    }

    @action.bound
    async getProccessByProfileData(profileFilterNumber) {
        const keys = Object.keys(this.processes);
        const ids = this.getIdsByJobProfileCompany(keys);
        if (ids) {
            await Promise.all([
                jobStore.getJobsBySlugs(ids.job),
                profileStore.getProfilesBySlugs(ids.profile, false, profileFilterNumber),
                companyStore.getCompaniesBySlugs(ids.company)
            ]);
            this.processesByProfileIdData = await this.buildGridData(keys, false, profileFilterNumber);
        }
    }

    @action.bound
    async getPipelinesGridData(isAll) {
        const keys = isAll ? Object.keys(this.allProcesses) : Object.keys(this.processes);
        const ids = this.getIdsByJobProfileCompany(keys, isAll);
        if (ids) {
            await Promise.all([
                jobStore.getJobsBySlugs(ids.job, isAll),
                profileStore.searchProfiles([], ids.profile),
                // profileStore.getProfilesBySlugs(ids.profile),
                companyStore.getCompaniesBySlugs(ids.company)
            ]);
            this.processesPipelinesGrid = await this.buildGridData(keys, isAll);

            this.processesPipelinesGridAll = Object.assign(
                this.processesPipelinesGrid, {});
        }
    }

    buildGridData = async (keys, isAll, filter) => {
        await userStore.getRecruiters();
        return keys.reduce((result, key) => {
            const processStore = isAll ? this.allProcesses : this.processes;
            const process = processStore[key];
            if (process) {
                let profile;
                if (filter === constants.PROFILES_FOR_COMPANIES_ACTIVITIES_SCREEN) {
                    profile = profileStore.profilesCompaniesActivities[process.profileSlug]
                }
                else {
                    profile = profileStore.profiles[process.profileSlug];
                }
                const company = companyStore.companies[process.companySlug];
                const job = isAll ? jobStore.allJobs[process.jobSlug] : jobStore.jobs[process.jobSlug];
                if (job && company && profile) {
                    result.push(
                        {
                            processId: process._id,
                            processSlug: process.slug,
                            slug: process.slug,
                            jobId: job._id,
                            jobSlug: job.slug,
                            companyId: company._id,
                            companySlug: company.slug,
                            placementContactPersonData: userStore.recruitersList.find(rec => rec._id === company.placementContactPerson),
                            profileId: profile._id,
                            profileSlug: profile.slug,
                            profileImageUrl: utilsService.getProfileImage(profile),
                            profileName: profile.firstName + ' ' + profile.lastName,
                            profilePosition:
                                utilsService.getValueFromArray(profile.employment, 0, 'position') ?
                                    utilsService.getValueFromArray(profile.employment, 0, 'position') + ' at ' +
                                    utilsService.getValueFromArray(profile.employment, 0, 'companyName') : null,
                            profilePositionCompanyName: utilsService.getValueFromArray(profile.employment, 0, 'companyName'),
                            profileCity: profile.locationData ? profile.locationData.city : '',
                            profileCountry: profile.locationData ? profile.locationData.country : '',
                            profilePhone: utilsService.getValueFromArray(profile.phones, 0, 'phone'),
                            jobCity: job.location ? job.location.city : '',
                            jobPosition: job.title,
                            status: process.status,
                            statusString: utilsService.getStatusByEnum(process.status),
                            salary: utilsService.buildSalaryLimit(profile.desiredSalary, job.salary),
                            positionType: utilsService.buildMatchList(profile.positionTypes, [job.positionType]),
                            seniority: utilsService.buildMatchList([profile.seniority], job.seniorityLevel),
                            skills: utilsService.buildMatchList(profile.skills, job.skills),
                            languages: utilsService.buildMatchList(profile.languages?.map(item => item.language), job.languages),
                            jobData: job,
                            companyData: company,
                            profileData: profile,
                            update: utilsService.getPassedDaysFromDate(
                                utilsService.getLastUpdate(process)),
                            lastUpdate: utilsService.getLastUpdate(process),
                            fit: process.fit,
                            _id: process._id,
                            dateCreated: process.dateCreated,
                            eventLog: process.eventLog
                        });
                }
            }
            return result;
        }, []);
    };

    @action.bound
    getTableAll() {
        return this.processesPipelinesGridAll;
    }

    @action.bound
    async addNote(id, body) {
        const response = await fetchService.authenticatedPut(
            `/processes/${id}/notes`, body);
        this.processes[response.data.slug].notes = response.data.notes;
        this.allProcesses[response.data.slug].notes = response.data.notes;
    }

    @action.bound
    async removeNote(processId, noteId) {
        const response = await fetchService.authenticatedDelete(
            `/processes/${processId}/notes/${noteId}`);
        this.processes[processId].notes = response.data.notes;
        this.allProcesses[processId].notes = response.data.notes;
    }

    @action.bound
    async changeStatus(id, body, isSingleJob, isSingleCompanyView, shouldShowToast = true) {
        // AllProcesses
        const response = await fetchService.authenticatedPut(
            `/processes/${id}/status`, body);
        await this.handleChangeStatusRequestFinished([response.data], isSingleJob, isSingleCompanyView, shouldShowToast);
    }

    @action.bound
    // works only for recruiter
    async changeStatuses(body, isSingleJob, isSingleCompanyView, shouldShowToast = true) {
        const response = await fetchService.authenticatedPut(
            `/processes-change-status`, body);

        await this.handleChangeStatusRequestFinished(response.data, isSingleJob, isSingleCompanyView, shouldShowToast);
    }

    async handleChangeStatusRequestFinished(data = [], isSingleJob, isSingleCompanyView, shouldShowToast) {
        data.map(item => item).forEach(item => {
            const slug = item.slug;
            if (item.isOpen) {
                if (this.processes[slug]) {
                    this.processes[slug].status = item.status;
                    this.processes[slug].eventLog = item.eventLog;
                } else {
                    this.processes[slug] = item;
                }
            }
            else {
                delete this.processes[slug];
            }
    
            if(this.allProcesses[slug]) {
                this.allProcesses[slug].status = item.status;
                this.allProcesses[slug].eventLog = item.eventLog;
            }
            else {
                this.allProcesses[slug] = item;
            }
        })

        if (!this.processesLoaded && !jobStore.jobToShow && !companyStore.companyToShow) {
            await jobStore.getJobs();
            const jobSlugs = jobStore.getJobSlugs();
            await this.getProcesses(true, jobSlugs);
        }

        await this.getPipelinesGridData();
        this.pipeLinesSet = false;
        this.setTableCounters();
        this.getTableIndex();

        if (jobStore.jobToShow) {
            const singleJobColumn = this.tableLastIndexSingleJob ? this.tableLastIndexSingleJob : 0;
            const jobSlug = jobStore.jobToShow.slug;
            this.filterProcessesByStatusAndJob(singleJobColumn, jobSlug);
            this.setSingleJobTableCounters(jobSlug);
        }

        if (companyStore.companyToShow) {
            const singleJobColumn = this.tableLastIndexCompanyJob ? this.tableLastIndexCompanyJob : 0;
            const companySlug = companyStore.companyToShow.slug;
            this.filterProcessesByStatusAndCompany(singleJobColumn, companySlug);
            this.setSingleCompanyTableCounters(companySlug);
        }

        data.map(item => item).forEach(item => {
            if (shouldShowToast) {
                let toastMessage = utilsService.getStatusByEnum(item.status);
                if (toastMessage === 'New') {
                    toastMessage = 'Request sent to talent';
                }
                else {
                    toastMessage = 'Talent moved to ' + toastMessage;
                }
                notificationsStore.showToast(toastMessage, 'success');
            }
        })
    }

    @action.bound
    async getSingleJobTableData(job) {
        const allProcessesKeys = Object.keys(this.processes);
        const relevantProcessesKeys = allProcessesKeys.filter(
            prockey => this.processes[prockey].jobSlug === job.slug);
        const ids = this.getIdsByJobProfileCompany(relevantProcessesKeys);

        if (ids) {
            await Promise.all([
                jobStore.getJobsBySlugs(ids.job),
                profileStore.getProfilesBySlugs(ids.profile),
                companyStore.getCompaniesBySlugs(ids.company)
            ]);
            this.processesPipelinesSingleJobTableByJobId[job.slug] = await this.buildGridData(
                relevantProcessesKeys);
        }
    }

    findProcessSlugById(id) {
        return Object.keys(this.allProcesses).find(key => this.allProcesses[key]._id === id);
    }

    findProcessesBySlugs(slugs) {
        const processes = [];
        slugs.forEach(slug => {
            const process = Object.values(this.allProcesses).find(value => value.slug === slug);
            if (process) {
                processes.push(process);
            }
        });
        return processes;
    }

    @action.bound
    searchProcesses(value) {
        // Why For? Fastest
        // Why Regex? Fastest
        const tempResults = [];
        const listLength = this.processesPipelinesGridAll.length;
        for (let i = listLength - 1; i >= 0; i--) {
            const currentItem = this.processesPipelinesGridAll[i];
            const expr = RegExp(`${value.toLowerCase()}`);
            if (expr.test(currentItem.profileName.toLowerCase()) && utilsService.checkIfExposed(currentItem)) {
                tempResults.push(currentItem);
            }
        }

        this.searchResults = tempResults;
    }

    @action.bound
    async getProcessesAndProfilesByCompany(companySlug) {
        const response = await fetchService.authenticatedPost(
            `/processes-profiles-by-company`, { companySlug });
        return response.data;
    }

    setProcessesIdsForNavigation(jobSlug, processSlug) {
        if (jobSlug !== this.processesIdsForProfileNavigation.jobSlug) {
            this.processesIdsForProfileNavigation.jobSlug = jobSlug;

            const keys = Object.keys(this.processes);
            this.processesIdsForProfileNavigation.ids = [];

            for (let i = keys.length - 1; i >= 0; i--) {
                const key = keys[i];
                if (this.processes[key].jobSlug === jobSlug) {
                    this.processesIdsForProfileNavigation.ids.push(this.processes[key].slug);
                }
            }
            this.processesIdsForProfileNavigation.index = this.processesIdsForProfileNavigation.ids.indexOf(processSlug);
        }
    }

    setProcessesIndexForNavigation(index) {
        this.processesIdsForProfileNavigation.index = index;
    }

    sortApplicantsColumnByKey(key, isDesc, isSingleJobView) {
        const dataToSort = isSingleJobView ? this.processesPipelinesSingleJobGrid : this.processesPipelinesGrid;
        let sortedData = null;
        if (isDesc) {
            sortedData = dataToSort.sort((a, b) => Number(b[key]) - Number(a[key]));
        } else {
            sortedData = dataToSort.sort((a, b) => Number(a[key]) - Number(b[key]));
        }

        if (sortedData && isSingleJobView) {
            this.processesPipelinesSingleJobGrid.replace(sortedData);
        } else if (sortedData) {
            this.processesPipelinesGrid.replace(sortedData);
        }
    }

    async getDiscussions(processId) {
        const response = await fetchService.authenticatedGet(`/discussions/${processId}`);
        return response.data;
    }

    async getDiscussionsByRecruiter(recruiterIds) {
        const response = await fetchService.authenticatedPost(`/discussions-conversations`, {userId: recruiterIds});
        return response.data;
    }

    async postDiscussion(body) {
        const response = await fetchService.authenticatedPost('/discussions', body);
        return response.data;
    }

    async updateReadStatusDiscussion(processId, isRead) {
        const response = await fetchService.authenticatedPatch(`/discussions/${processId}`, {isRead});
        return response.data;
    }
}

const processStore = new ProcessStore();

export default processStore;